<template>
    <page-head-wrapper>
        <div class="user-profile">
            <div class="user-wrapper-box">
                <div class="title">基本信息</div>
                <div class="basic-wrap">
                    <div class="avatar-wrap">
                        <div class="mp-avatar-box" v-if="adminAvatar">
                            <img class="img" :src="adminAvatar">
                        </div>
                        <div class="mp-default-avatar" v-else>
                            {{adminRealnameShow}}
                        </div>
                        <a-upload
                            class="edit-btn"
                            name="file"
                            :before-upload="beforeUpload"
                            @change="uploadOnChange"
                            :showUploadList="false"
                            accept="image/*"
                        >
                            <span class="edit-text">修改头像</span>
                        </a-upload>
                    </div>
                    <a-form-model
                        class="form"
                        :label-col="{ span: 5 }"
                        :wrapper-col="{ span: 18, offset: 1 }"
                    >
                        <a-row>
                            <a-col :span="12">
                                <a-form-model-item label="真实姓名">
                                    <div class="text-show" v-if="!editName">
                                        <span class="item-text">{{adminRealname}}</span>
                                        <!-- <span class="item-edit" @click="editName = true">
                                            <icPencil />
                                        </span> -->
                                    </div>
                                    <!-- <div v-if="editName">
                                        <a-input placeholder="请输入真实姓名" v-model="currentRealname" style="width: 50%" />
                                        <a-button style="margin-left: 8px;" @click="editName = false">取消</a-button>
                                        <a-button style="margin-left: 8px;" type="primary" @click="submitEditName">确认</a-button>
                                    </div> -->
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="所属角色">
                                    <span class="item-text">{{adminPosition}}</span>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12">
                                <a-form-model-item label="手机号码">
                                    <span class="item-text">{{adminCellphone}}</span>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-form-model>
                    
                </div>
            </div>
            <div class="user-wrapper-box">
                <div class="title">修改密码</div>
                <div class="pwd-wrap">
                    <a-radio-group v-model="editPwdType" @change="onChangeType">
                        <a-radio :value="2">
                            通过密码修改
                        </a-radio>
                        <a-radio :value="1" style="margin-left: 40px">
                            通过验证码修改
                        </a-radio>
                    </a-radio-group>
                    <a-form-model
                        v-if="editPwdType === 2"
                        ref="pwdForm"
                        :rules="rulesPwd"
                        :model="pwdForm"
                        class="pwd-form"
                        label-align="left"
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 12 }"
                        autoComplete="off"
                    >
                        <a-form-model-item label="旧密码" prop="old">
                            <a-input :type="pwdshow.old?'text':'password'" placeholder="请输入旧密码"
                                v-model="pwdForm.old" autoComplete="new-password"
                            >
                                <icEyeClose class="cursor" v-show="!pwdshow.old" slot="suffix" @click="pwdshow.old = true" />
                                <icEyeOpen class="cursor" v-show="pwdshow.old" slot="suffix"  @click="pwdshow.old = false" />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item label="新密码" prop="new">
                            <a-input :type="pwdshow.new?'text':'password'" placeholder="请输入新密码"
                                v-model="pwdForm.new" autoComplete="new-password"
                            >
                                <icEyeClose class="cursor" v-show="!pwdshow.new" slot="suffix" @click="pwdshow.new = true"  />
                                <icEyeOpen class="cursor" v-show="pwdshow.new" slot="suffix" @click="pwdshow.new = false"  />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item label="确认密码" prop="new2">
                            <a-input :type="pwdshow.new2?'text':'password'" placeholder="请重新输入密码"
                                v-model="pwdForm.new2" autoComplete="new-password"
                            >
                                <icEyeClose class="cursor" v-show="!pwdshow.new2" slot="suffix" @click="pwdshow.new2 = true"  />
                                <icEyeOpen class="cursor" v-show="pwdshow.new2" slot="suffix" @click="pwdshow.new2 = false"  />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button @click="resetPwdForm">
                                重置
                            </a-button>
                            <a-button type="primary"  style="margin-left: 16px" @click="submitPwdForm">
                                确认
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                    <a-form-model
                        v-if="editPwdType === 1"
                        ref="codeForm"
                        :rules="rulesCode"
                        :model="codeForm"
                        class="pwd-form"
                        label-align="left"
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 12 }"
                        autoComplete="off"
                    >
                        <a-form-model-item label="验证码" prop=code>
                            <a-input placeholder="请输入验证码" style="width: 50%;" v-model="codeForm.code">
                            </a-input>
                            <a-button class="mp-grey-btn" style="width: 30%; margin-left: 8px"
                                @click="getSmsCode"
                                :disabled="smsCaptcha.isWaiting"
                            >
                                {{smsCaptcha.showText}}
                            </a-button>
                        </a-form-model-item>
                        <a-form-model-item label="新密码" prop="new">
                            <a-input :type="codeshow.new?'text':'password'" placeholder="请输入新密码" v-model="codeForm.new"
                                autoComplete="new-password"
                            >
                                <icEyeClose class="cursor" v-show="!codeshow.new" slot="suffix" @click="codeshow.new = true"  />
                                <icEyeOpen class="cursor" v-show="codeshow.new" slot="suffix" @click="codeshow.new = false"  />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item label="确认密码" prop="new2">
                            <a-input :type="codeshow.new2?'text':'password'" placeholder="请重新输入密码" v-model="codeForm.new2"
                                autoComplete="new-password"
                            >
                                <icEyeClose class="cursor" v-show="!codeshow.new2" slot="suffix" @click="codeshow.new2 = true"  />
                                <icEyeOpen class="cursor" v-show="codeshow.new2" slot="suffix" @click="codeshow.new2 = false"  />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button @click="resetCodeForm">
                                重置
                            </a-button>
                            <a-button type="primary" style="margin-left: 16px" @click="submitCodeForm">
                                确认
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </div>
            </div>
        </div>
    </page-head-wrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// import icPencil from '@/assets/icons/ic_pencil.svg'
import icEyeClose from '@/assets/icons/ic_eye_close.svg'
import icEyeOpen from '@/assets/icons/ic_eye_open.svg'
import {getSmsCaptcha} from '@/api/login'
import {updatePwd, updatePwdByPhone, updateAvatar} from '@/api/user'
import {validatePwd} from '@/utils/validate'
export default {
    components: {
        // icPencil,
        icEyeClose,
        icEyeOpen
    },
    computed: {
        ...mapGetters(['adminId', 'adminRealname', 'adminCellphone', 'adminPosition']),
        ...mapState({
            adminAvatar: state => state.user.adminAvatar
        }),
        adminRealnameShow: function() {
            return this.adminRealname.slice(this.adminRealname.length - 2)
        }
    },
    created() {
        this.currentRealname = this.adminRealname
    },
    data() {
        let validatePwdNew = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请输入密码'))
            } else if (!validatePwd(value.trim())) {
                callback(new Error('密码同时包含大写字母、小写字母或数字中的两种及以上，8-16位'))
            } else {
                callback()
                if (this.pwdForm.new2 !== '') {
                    this.$refs.pwdForm.validateField('new2')
                }
            }
        };
        let validatePwdNew2 = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请重新输入密码'))
            } else if (value !== this.pwdForm.new) {
                callback(new Error("两次密码不一致"))
            } else {
                callback()
            }
        };

        let validateCodeNew = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请输入密码'))
            } else if (!validatePwd(value.trim())) {
                callback(new Error('密码同时包含大写字母、小写字母或数字中的两种及以上，8-16位'))
            } else {
                callback()
                if (this.codeForm.new2 !== '') {
                    this.$refs.codeForm.validateField('new2')
                }
            }
        };
        let validateCodeNew2 = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请重新输入密码'))
            } else if (value !== this.codeForm.new) {
                callback(new Error("两次密码不一致"))
            } else {
                callback()
            }
        };
        return {
            // 修改姓名
            editName: false,
            editPwdType: 2,
            currentRealname: '',

            // 通过旧密码修改
            pwdForm: {
                old: '',
                new: '',
                new2: ''
            },
            pwdshow: {
                old: false,
                new: false,
                new2: false
            },
            rulesPwd: {
                old: [{ required: true, message: '请输入旧密码', trigger: 'blur'}],
                new: [{ required: true, validator: validatePwdNew, trigger: 'blur'}],
                new2: [{ required: true, validator: validatePwdNew2, trigger: 'blur'}]
            },
            
            // 通过验证码修改
            codeForm: {
                code: '',
                new: '',
                new2: ''
            },
            codeshow: {
                new: false,
                new2: false
            },
            rulesCode: {
                code: [{ required: true, message: '请输入验证码', trigger: 'blur'}],
                new: [{ required: true, validator: validateCodeNew, trigger: 'blur'}],
                new2: [{ required: true, validator: validateCodeNew2, trigger: 'blur'}]
            },

            // 获取验证码
            smsCaptcha: {
                isWaiting: false,
                timer: null,
                second: 60,
                showText: '获取验证码',
            }
        }
    },
    methods: {
        // 提交姓名修改
        submitEditName() {
            if (this.currentRealname.trim() === '') {
                this.$message.error('请输入姓名')
            }
        },
        // 修改头像
        beforeUpload(file, fileList) {
            // console.log(file, fileList, 'beforeUpload')
            return false
        },
        // 上传头像
        uploadOnChange(info) {
            console.log(info, 'uploadOnChange')
            let file = info.file
            if (!(/^image\/(.*)$/ig.test(file.type))) {
                this.$message.error('请上传图片')
                return
            }
            // test
            // let reader = new FileReader()
            // reader.onload = function() {
            //     console.log(this.result)
            // }
            // reader.readAsDataURL(file)

            let objectUrl = URL.createObjectURL(file)
            this.$showLoading()
            this.compressImg(objectUrl, (dataUrl) => {
                console.log(dataUrl)
                updateAvatar({
                    avatarStr: dataUrl, // dataUrl,
                    adminId: this.adminId
                }).then(res => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$store.dispatch('getAdminInfo').then(() => {
                            this.$hideLoading()
                        })
                    } else {
                        this.$hideLoading()
                    }
                }).catch(err => {
                    this.$hideLoading()
                    console.warn(err)
                })
            })
        },
        // 压缩头像
        compressImg(src, callback){
            console.log(src)
            let img = new Image()
            img.src = src
            img.onload = function() {
                var canvasEl = document.createElement('canvas');
                let ctx=canvasEl.getContext('2d');
                console.log(img.src.length, img.width,img.height)
                // let initSize=img.src.length;
                let width=img.width;
                let height=img.height;
                let whRate = height / width;

                let create_width, create_height;
                width > 96 ? create_width = 96 : create_width = width;
                create_height = create_width * whRate;
                canvasEl.width = create_width;
                canvasEl.height = create_height;
                ctx.drawImage(img, 0, 0, create_width, create_height);
                let dataURL = canvasEl.toDataURL("image/jpeg", 0.92);
                callback?callback(dataURL):null; //调用回调函数
            }
            
            
        },

        // 切换修改密码方式
        onChangeType(e) {
            console.log('radio checked', e.target.value);
            this.editPwdType = e.target.value
            if (e.target.value === 1) {
                this.$refs.pwdForm.clearValidate()
            }
            if (e.target.value === 2) {
                this.$refs.codeForm.clearValidate()
            }
        },
        // 重置表单 - 通过旧密码修改
        resetPwdForm() {
            this.$refs.pwdForm.resetFields()
        },
        // 提交表单 - 通过旧密码修改
        submitPwdForm() {
            this.$refs.pwdForm.validate(valid => {
                if (valid) {
                    console.log('submit-pwdForm =>', this.pwdForm)
                    this.$showLoading({tip: '正在提交...'})
                    updatePwd({
                        oldPwd: this.pwdForm.old.trim(),
                        newPwd: this.pwdForm.new.trim(),
                        confirmPwd: this.pwdForm.new2.trim(),
                        adminId: this.adminId,
                    }).then(res => {
                        console.log(res)
                        if (res.code === 200) {
                            // this.$message.success('密码修改成功')
                            this.$success({
                                title: '提示',
                                content: '密码修改成功',
                                centered: true
                            })
                            this.$refs.pwdForm.resetFields()
                        } else {
                            this.$message.error(res.msg || '网路异常')
                        }
                    }).catch(err => {
                        console.warn(err)
                    }).finally(() => {
                        this.$hideLoading()
                    })
                }
            })
        },
        // 重置表单 - 通过验证码修改
        resetCodeForm() {
            this.$refs.codeForm.resetFields()
        },
        // 提交表单 - 通过验证码修改
        submitCodeForm() {
            this.$refs.codeForm.validate(valid => {
                if (valid) {
                    console.log('submit-codeForm =>', this.codeForm)
                    this.$showLoading({tip: '正在提交...'})
                    updatePwdByPhone({
                        captcha: this.codeForm.code.trim(),
                        newPwd: this.codeForm.new.trim(),
                        confirmPwd: this.codeForm.new2.trim(),
                        adminId: this.adminId,
                    }).then(res => {
                        console.log(res)
                        if (res.code === 200) {
                            this.$success({
                                title: '提示',
                                content: '密码修改成功',
                                centered: true
                            })
                            this.$refs.codeForm.resetFields()
                        } else {
                            this.$message.error(res.msg || '网路异常')
                        }
                    }).catch(err => {
                        console.warn(err)
                    }).finally(() => {
                        this.$hideLoading()
                    })
                }
            })
        },

        // 获取验证码
        getSmsCode() {
            if (!this.smsCaptcha.isWaiting) {
                this.smsCaptcha.isWaiting = true

                // 重置定时器
                let resetTimer = () => {
                    clearInterval(this.smsCaptcha.timer)
                    this.smsCaptcha.second = 60
                    this.smsCaptcha.showText = '获取验证码'
                    this.smsCaptcha.isWaiting = false
                }

                // 请求接口
                getSmsCaptcha({phone: this.adminCellphone}).then(res => {
                    // console.log(res)
                    if (res.errorCode === 200) {
                        this.$message.success('验证码发送成功!')
                    } else {
                        this.$message.error(res.errorMsg || '网络异常，请稍后重试')
                        resetTimer()
                    }
                }).catch(err => {
                    console.warn(err)
                    resetTimer()
                })

                // 倒计时
                clearInterval(this.smsCaptcha.timer)
                this.smsCaptcha.showText = this.smsCaptcha.second + 's'
                this.smsCaptcha.timer = setInterval(() => {
                    if (this.smsCaptcha.second > 0) {
                        this.smsCaptcha.second --
                        this.smsCaptcha.showText = this.smsCaptcha.second + 's'
                    } else {
                        resetTimer()
                    }
                }, 1000)
            }
        },
    },
}
</script>

<style lang="less" scoped>
.user-wrapper-box {
    padding: 24px 40px 0;
    border: 1px solid #E6E6E6;
    background-color: #fff;
    min-width: 900px;
    margin-bottom: 24px;
    .title {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }
    .basic-wrap {
        margin-top: 40px;
        padding-left: 88px;
        position: relative;
    }
    .avatar-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        padding-top: 8px;
        display: flex;
        flex-flow: column;
        .edit-btn {
            margin-top: 8px; cursor: pointer;
            .edit-text:hover {
                color: #0267ED;
            }
        }
    }
    .form {
        .text-show {
            display: flex;
            align-items: center;
        }
        .item-edit {
            margin-left: 16px;
            padding: 0 8px;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .pwd-wrap {
        margin-top: 24px;
        position: relative;
    }
    .pwd-form {
        margin-top: 24px;
        width: 800px;
    }
    .cursor {
        cursor: pointer;
    }
    
}
</style>